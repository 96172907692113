import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../../components/Layout"
import * as styles from "../../styles/firm-philosophy.module.css"
import Sidebar from "../../components/Sidebar"
import { GatsbySeo } from "gatsby-plugin-next-seo"

export default function EnvironmentalLaw({ data }) {
  return (
    <Layout>
      <GatsbySeo
        title="Charleston South Carolina Environmental Lawyers | Tecklenburg, Jenkins & Jacoby, LLC"
        description="Our attorneys have handled numerous
            environmental law disputes on behalf of landowners, generators,
            transporters, contractors and other businesses. Increased
            responsibility for releases of hazardous and petroleum products
            necessitates that clients have a resource to turn to when a mishap
            occurs."
        keywords="Charleston, South Carolina, Maritime Attorney, Maritime Lawyer, Lawyer, Maritime, Attorney, Admiralty Attorney, Admiralty Lawyer, Admiralty, Business Attorney, Business Lawyer, Business, Railroad Attorney, Railroad Lawyer, Railroad, Litigation Attorney, Litigation Lawyer, Litigation, Construction Attorney, Construction Lawyer, Construction, Estate Attorney, Estate Lawyer, Estate"
        canonical="https://www.tecklaw.net/practice-areas/south-carolina-environmental-law/"
      />
      <section className={styles.header}>
        <section className={styles.sidebar}>
          <Sidebar data={data} />
        </section>
        <section className={styles.content}>
          <h1>Top South Carolina Environmental Lawyers</h1>
          <h2>Knowledgeable Environmental Lawyers</h2>
          <p>
            <Link to="/attorneys">Our attorneys</Link> have handled numerous
            environmental law disputes on behalf of landowners, generators,
            transporters, contractors and other businesses. Increased
            responsibility for releases of hazardous and petroleum products
            necessitates that clients have a resource to turn to when a mishap
            occurs. We also assist clients with the management of environmental
            cleanups and any resulting claims.
          </p>
          <h2>What is Environmental Law?</h2>
          <p>
            Environmental law is complex, and made up of global, international,
            national, state and local statutes, treaties, conventions,
            regulations and policies which seek to protect the environment and
            natural resources affected, impacted or endangered by human
            activities. The{" "}
            <a href="https://www.epa.gov/">
              United States Environmental Protection Agency
            </a>{" "}
            administers the laws required to protect human health and to
            safeguard the natural environment.
          </p>
          <h2>Environmental Litigation in South Carolina </h2>
          <p>
            At Tecklenburg, Jenkins & Jacoby, LLC, we provide regulatory
            counseling and representation in connection with permitting and
            operations, as well as administrative and enforcement proceedings.
            We also routinely furnish advice on regulatory issues that arise in
            real estate, lending, and corporate transactions. Our expertise and
            breadth of experience allow us to take a multi-level approach to
            solving environmental legal issues, and to address the
            sometimes-competing issues involved in the regulatory laws affecting
            health, safety and the environment.
          </p>
          <p>
            Our attorneys have substantial real world experience in representing
            and advising clients in matters involving environmental, health, and
            safety under the following federal statutes, as well as
            corresponding state and local laws:
          </p>
          <ul>
            <li>
              <h3>
                <a href="https://www.epa.gov/superfund/superfund-cercla-overview">
                  Comprehensive Environmental Response, Compensation, and
                  Liability Act
                </a>{" "}
                (CERCLA)
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://www2.epa.gov/laws-regulations/summary-clean-air-act">
                  Clean Air Act
                </a>
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://www2.epa.gov/laws-regulations/summary-clean-water-act">
                  Clean Water Act
                </a>{" "}
                (CWA)
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://www2.epa.gov/enforcement/oil-pollution-act-opa-and-federal-facilities">
                  Oil Pollution Act
                </a>{" "}
                (OPA)
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://www2.epa.gov/laws-regulations/summary-occupational-safety-and-health-act">
                  Occupational Safety and Health Act
                </a>
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://www2.epa.gov/laws-regulations/summary-resource-conservation-and-recovery-act">
                  Resource Conservation and Recovery Act
                </a>{" "}
                (RCRA)
              </h3>
            </li>
            <li>
              <h3>
                <a href="http://www2.epa.gov/laws-regulations/summary-toxic-substances-control-act">
                  Toxic Substances Control Act
                </a>
              </h3>
            </li>
          </ul>
          <h2>Environmental Litigation Takes Experience</h2>
          <p>
            The environmental attorneys at of Tecklenburg, Jenkins & Jacoby have
            extensive litigation experience, representing clients in a multitude
            of issues before agencies as well as state and federal courts. Our
            attorneys are equipped to handle numerous environmental law disputes
            involving claims under CERCLA, RCRA, OPA, CWA and state law.
            Environmental lawsuit costs can be exorbitant if not controlled from
            the outset. Our attorneys appreciate the importance of gathering the
            facts, hiring the necessary experts, formulating an accurate
            assessment of the potential expenses and liabilities, and working to
            obtain an early resolution when possible. Our lawyers have the
            necessary knowledge and experience to represent you in any stage of
            an agency proceeding or litigation, from initial negotiations with
            an agency to appeals in state or federal court. Our areas of
            practice include:
          </p>
          <ul>
            <li>
              <h3>Clean Water Act and Related Water Quality Issues</h3>
            </li>
            <li>
              <h3>CERCLA and Related Pollution Issues</h3>
            </li>
            <li>
              <h3>Toxic Torts</h3>
            </li>
            <li>
              <h3>Hazardous Waste Management</h3>
            </li>
            <li>
              <h3>Public and Private Nuisance</h3>
            </li>
            <li>
              <h3>Wetlands</h3>
            </li>
            <li>
              <h3>Consent Decrees</h3>
            </li>
            <li>
              <h3>Natural Resources Damages</h3>
            </li>
          </ul>
          <h2>Seek Experienced Representation</h2>
          <p>
            If you need legal advice pertaining to the protection of land,
            wildlife or natural resources or the regulation of harmful emissions
            into air and water, an environmental lawyer from Tecklenburg &
            Jenkins, LLC can help. <Link to="/contact">Contact us</Link> today!
          </p>
        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query EnvironmentalLawSidebarImages {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "south-carolina-environmental-law" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
